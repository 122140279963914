import {
  ApiResponse,
  FormattedOdd,
  GameAlignmentType,
  GameOdds,
  LineType,
  Odd,
  Odd2,
  OddOutcome,
  OddVariant,
  Odds,
  OddsFormat,
  Sport,
} from "common";

export async function getPreGameOdds(
  sport: Sport,
  year: string,
  hottest?: boolean,
  limit?: number
) {
  const base: string = `${process.env.NEXT_PUBLIC_API_URL}/api/sports/pre-game-odds/${sport}/${year}`;
  const params: URLSearchParams = new URLSearchParams();
  if (hottest) {
    params.append("orderBy", "hottest");
  }
  if (limit) {
    params.append("limit", `${limit}`);
  }
  const request: string = `${base}?${params}`;
  const res = await fetch(request);
  if (!res.ok && res.status !== 404) {
    throw new Error(`Failed to fetch game odds: HTTP status ${res.status}`);
  }
  return res.json();
}

export async function getNextGameOdds(
  sport: Sport,
  team: string
): Promise<ApiResponse<any>> {
  const base: string = `${process.env.NEXT_PUBLIC_API_URL}/api/sports/${sport}/next-game-odds/${team}`;
  const params: URLSearchParams = new URLSearchParams();
  const request: string = `${base}?${params}`;
  const res = await fetch(request);
  if (!res.ok) {
    return {
      status: "error",
      message: "No odds found.",
    };
  }
  const response = await res.json();
  return {
    status: "success",
    data: response,
  };
}

/**
 * TODO: Change to Odd.
 * @param odd
 * @param format
 * @returns
 */
function internalFormatOdd(format: OddsFormat, odd: number): string {
  if (odd) {
    switch (format) {
      case "decimal":
        return `${roundDecimal(americanToDecimal(odd)).toFixed(2)}`;
      case "fraction":
        return `${americanToFraction(odd)}`;
      default:
        return `${odd > 0 ? "+" : ""}${odd}`;
    }
  } else {
    return "TBD";
  }
}

/**
 * TODO: Change to "Wager" or "Bet"
 * @param format
 * @param odd
 * @returns
 */
export function formatOdd(
  format: OddsFormat = "american",
  odd?: Odd2,
  showOutcome: boolean = false
): FormattedOdd | undefined {
  if (odd) {
    switch (odd.type) {
      case LineType.Moneyline:
        return {
          type: odd.type,
          alignment: odd.alignment,
          value: undefined,
          payout: internalFormatOdd(format, odd.payout),
          outcome: odd.outcome,
        };
      case LineType.Spread:
        return {
          type: odd.type,
          alignment: odd.alignment,
          value: `${odd.value && odd.value > 0 ? "+" : ""}${parseFloat(
            `${odd.value}`
          )}`,
          payout: internalFormatOdd(format, odd.payout),
          outcome: odd.outcome,
        };
      case LineType.Total:
        return {
          type: odd.type,
          alignment: odd.alignment,
          value: `${
            showOutcome && odd.outcome === OddOutcome.Push
              ? "P"
              : odd.alignment === GameAlignmentType.Away
              ? "o"
              : "u"
          }${parseFloat(`${odd.value}`)}`,
          payout: internalFormatOdd(format, odd.payout),
          outcome: odd.outcome,
        };
    }
  }
  return undefined;
}

export function buildOdds(alignment: "home" | "away", odds: GameOdds): Odds {
  return {
    spread: {
      type: "spread",
      value: alignment === "home" ? odds.homeSpread : odds.awaySpread,
      payout:
        alignment === "home" ? odds.homeSpreadPayout : odds.awaySpreadPayout,
      outcome:
        alignment === "home" ? odds.homeSpreadOutcome : odds.awaySpreadOutcome,
    },
    moneyline: {
      type: "moneyline",
      value: alignment === "home" ? odds.homeMoneyline : odds.awayMoneyline,
      outcome:
        alignment === "home"
          ? odds.homeMoneylineOutcome
          : odds.awayMoneylineOutcome,
    },
    total: {
      type: alignment === "home" ? "under" : "over",
      value: odds.overUnder,
      payout: alignment === "home" ? odds.underPayout : odds.overPayout,
      outcome: alignment === "home" ? odds.underOutcome : odds.overOutcome,
    },
  };
}

function gcd(a: number, b: number): number {
  if (!b) {
    return a;
  }
  return gcd(b, a % b);
}

function toFraction(decimal: number): string {
  const tolerance = 1.0e-6;
  let h1 = 1;
  let h2 = 0;
  let k1 = 0;
  let k2 = 1;
  let b = decimal;
  do {
    const a = Math.floor(b);
    let aux = h1;
    h1 = a * h1 + h2;
    h2 = aux;
    aux = k1;
    k1 = a * k1 + k2;
    k2 = aux;
    b = 1 / (b - a);
  } while (Math.abs(decimal - h1 / k1) > decimal * tolerance);

  const numerator = h1;
  const denominator = k1;
  const commonDivisor = gcd(numerator, denominator);

  let simplifiedNumerator = numerator / commonDivisor;
  let simplifiedDenominator = denominator / commonDivisor;

  if (simplifiedDenominator > 1 && simplifiedNumerator > 1) {
    const ratio = simplifiedNumerator / simplifiedDenominator;
    if (Math.abs(ratio - Math.round(ratio)) < tolerance) {
      simplifiedNumerator = Math.round(
        simplifiedNumerator / simplifiedDenominator
      );
      simplifiedDenominator = 1;
    }
  }

  return `${simplifiedNumerator}/${simplifiedDenominator}`;
}

function roundDecimal(decimal: number, places: number = 2): number {
  const factor = Math.pow(10, places);
  return Math.round(decimal * factor) / factor;
}

function fractionToDecimal(fraction: string): number {
  const [numerator, denominator] = fraction.split("/").map(Number);
  if (denominator === 0) {
    throw new Error("Invalid fraction, denominator cannot be 0");
  }
  return numerator / denominator + 1;
}

function americanToDecimal(americanOdds: number): number {
  let decimalOdds: number;
  if (americanOdds > 0) {
    decimalOdds = americanOdds / 100 + 1;
  } else if (americanOdds < 0) {
    decimalOdds = 100 / Math.abs(americanOdds) + 1;
  } else {
    throw new Error(`Invalid American odds value: ${americanOdds}`);
  }
  return decimalOdds;
}

function decimalToAmerican(decimalOdds: number): number {
  if (decimalOdds >= 2) {
    return (decimalOdds - 1) * 100;
  } else if (decimalOdds < 2) {
    return -100 / (decimalOdds - 1);
  } else {
    throw new Error("Invalid decimal odds value");
  }
}

function decimalToFraction(decimalOdds: number): string {
  return toFraction(decimalOdds - 1);
}

function fractionToAmerican(fraction: string): number {
  const decimal = fractionToDecimal(fraction);
  return decimalToAmerican(decimal);
}

function americanToFraction(americanOdds: number): string {
  const decimal = americanToDecimal(americanOdds);
  return decimalToFraction(decimal);
}
